<template>
  <div>
    <!-- <v-card flat max-width="200px">
      <v-layout wrap justify-start pa-2>
        <v-flex xs12 text-left>
          <span class="itemValue" style="color: #fdce48">Hive ID : </span>
          <span class="itemKey pl-2" style="color: #000">12345678925</span>
        </v-flex>
        <v-flex xs12 text-left>
          <span class="itemValue" style="color: #fdce48">Hive Status : </span>
          <span class="itemKey pl-2" style="color: #000">Good</span>
        </v-flex>
        <v-flex xs12 text-center py-1>
          <v-btn
            small
            depressed
            :ripple="false"
            color="#FDCE48"
            class="itemKey text-capitalize"
            to="/Admin/Apiary/5ff6cdd393f7baf426bd429f"
          >
            <span style="color: #fff"> View Details </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card> -->
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-3>
              <div id="map"></div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCaccNxbzwR9tMvkppT7bT7zNKjChc_yAw&libraries=visualization,drawing">
</script>
<script>
var marker;
export default {
  props: ["storage"],
  data() {
    return {
      map: "",
      mapData: [],
    };
  },
  mounted() {
    this.initMap();
  },
  computed: {
    myData() {
      if (this.storage.length > 0) return this.storage;
      else return [];
    },
  },
  methods: {
    initMap() {
      var vm = this;
      this.mapData = this.myData;
      var mapCanvas = document.getElementById("map");
      if (this.mapData.length > 0) {
        var mapOptions = {
          center: new google.maps.LatLng(
            this.mapData[0].location[1],
            this.mapData[0].location[0]
          ),
          zoom: 18,

          mapTypeId: google.maps.MapTypeId.TERRAIN,
        };

        vm.map = new google.maps.Map(mapCanvas, mapOptions);

        for (var i = 0; i < this.mapData.length; i++) {
          var lat = this.mapData[i].location[1];
          var lon = this.mapData[i].location[0];
          var latlngset = new google.maps.LatLng(lat, lon);
          var icon = {
            url: "http://arhiveadmin.leopardtechlabs.com/img/orangemarker.png", // url
            scaledSize: new google.maps.Size(20, 32), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(0, 0), // anchor
          };
          marker = new google.maps.Marker({
            map: vm.map,
            position: latlngset,
            icon: icon,
          });
          var content =
            `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Hive ID : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
            this.mapData[i].colonyidentifier +
            `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">Hive Status : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
            this.mapData[i].colonystatus +
            `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Hive/` +
            this.mapData[i]._id +
            `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

          var infowindow = new google.maps.InfoWindow();

          google.maps.event.addListener(
            marker,
            "click",
            (function (marker, content, infowindow) {
              return function () {
                infowindow.setContent(content);
                infowindow.open(vm.map, marker);
              };
            })(marker, content, infowindow)
          );
        }
      }
    },
  },
};
</script>