<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 py-2>
        <ApiaryDetail v-bind:storage="apiaryData" />
      </v-flex>
      <v-flex xs12 py-2>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 py-2>
        <v-layout wrap justify-start>
          <v-flex xs12 text-left>
            <span class="itemHeading"> Weather </span>
          </v-flex>
          <template v-for="(item, i) in weather">
            <v-flex xs12 sm12 md6 xl4 :key="i" py-2 pa-sm-2>
              <Weather v-bind:storage="item" />
            </v-flex>
          </template>
          <template v-if="weather.length < 1">
            <span class="itemKey"> No data available </span>
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-2>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 py-2>
        <v-layout wrap justify-start>
          <v-flex xs12 text-left>
            <span class="itemHeading"> Forage Observed </span>
          </v-flex>
          <template v-for="(item, i) in forage">
            <v-flex xs12 sm6 md4 xl3 :key="i" py-2 pa-sm-2>
              <Forage v-bind:storage="item" />
            </v-flex>
          </template>
          <template v-if="forage.length < 1">
            <span class="itemKey"> No data available </span>
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-2>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 py-2>
        <v-layout wrap justify-start>
          <v-flex xs12 text-left>
            <span class="itemHeading"> Hives </span>
          </v-flex>
          <template v-for="(item, i) in hives">
            <v-flex xs12 sm12 md6 xl4 :key="i" py-2 pa-sm-2>
              <Hives v-bind:storage="item" />
            </v-flex>
          </template>
          <template v-if="hives.length < 1">
            <span class="itemKey"> No data available </span>
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-2 v-if="hives.length > 0">
        <v-layout wrap justify-start>
          <v-flex xs12 text-left>
            <span class="itemHeading"> Map </span>
          </v-flex>
          <v-flex xs12 py-2 pa-sm-2>
            <Map v-bind:storage="hives" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ApiaryDetail from "./apiaryDetails";
import Weather from "./weather";
import Forage from "./forage";
import Hives from "./hives";
import Map from "./map";
export default {
  props: ["id"],
  components: {
    ApiaryDetail,
    Weather,
    Forage,
    Hives,
    Map,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      apiaryData: {},
      weather: [],
      forage: [],
      hives: [],
      userType: localStorage.getItem("userType"),
    };
  },
  beforeMount() {
    this.getData();
    this.getWeather();
    this.getForage();
    this.getHives();
  },
  methods: {
    getData() {
      this.appLoading = true;
      var link = "/cluster/adminget";
      if (this.userType != "Admin") link = "/cluster/get";
      axios({
        method: "GET",
        url: link,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.apiaryData = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getWeather() {
      this.appLoading = true;
      var link = "/cluster/weather/admingetlist";
      if (this.userType != "Admin") link = "/cluster/weather/getlist";
      axios({
        method: "GET",
        url: link,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          cluster: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.weather = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getForage() {
      this.appLoading = true;
      var link = "/cluster/forage/admingetlist";
      if (this.userType != "Admin") link = "/cluster/forage/getlist";
      axios({
        method: "GET",
        url: link,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          cluster: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.forage = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getHives() {
      this.appLoading = true;
      var link = "/colony/admingetlist";
      if (this.userType != "Admin") link = "/colony/getlist";
      axios({
        method: "GET",
        url: link,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          cluster: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hives = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>