<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Apiary Details </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end v-if="storage && storage.user">
      <v-flex xs2 @click="pdf()">
        <!-- <a
          target="_blank"
          download
          class="downlink"
          :href="
            'http://65.0.74.151/admin/user/getclusterpdf?clusterid=' +
            storage._id +
            '&uid=' +
            storage.user._id
          "
        > -->
          <span class="itemValue" style="cursor:pointer"> Download QR </span>
        <!-- </a> -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Apiary ID</span> <br />
        <span class="itemValue">
          {{ storage.apiaryid }}
          <span v-if="!storage.apiaryid"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Apiary Code</span> <br />
        <span class="itemValue">
          {{ storage.code }}
          <span v-if="!storage.code">-</span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Apiary Number</span> <br />
        <span class="itemValue">
          {{ storage.clusternum }}
          <span v-if="!storage.clusternum"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Hive Count</span> <br />
        <span class="itemValue">
          {{ storage.colonycount }}
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2 v-if="userType == 'Admin'">
        <span class="itemKey">Apiary Owned By</span> <br />
        <span v-if="storage.user" class="itemValue">
          <router-link :to="'/Admin/Users/' + storage.user._id">
            {{ storage.user.username }}
          </router-link>
          <span v-if="!storage.user.username"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Created Date</span><br />
        <span v-if="storage.createddate" class="itemValue">
          {{ formatDate(storage.createddate) }}
        </span>
      </v-flex>
      <v-flex xs12 sm6 text-left py-2>
        <span class="itemKey">Apiary Location</span> <br />
        <span class="itemValue">
          {{ storage.locationname }}
          <span v-if="!storage.locationname"> - </span>
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      userType: localStorage.getItem("userType"),
      hives: [],
    };
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
    pdf() {
      this.appLoading = true;
      var link = "/admin/user/getclusterpdf";
      if (this.userType != "Admin") link = "/user/getclusterpdf/v1";
      axios({
        method: "GET",
        url: link,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          clusterid: this.storage._id,
          uid: this.storage.user._id,
        },
      })
         .then((response) => {
           var url = window.URL.createObjectURL(new Blob([response.data]))
      var a = document.createElement('a')
      a.href = url
      a.download ="QRCODE.pdf"
      a.dispatchEvent(new MouseEvent("click"))
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>