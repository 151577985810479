<template>
  <div>
    <v-card>
      <v-layout wrap justify-center pa-2>
        <v-flex xs12 sm3 align-self-center>
          <vue-qrcode :value="storage.tagid" />
          <v-card color="red" v-if="storage.islate">
            <span class="itemKey" style="color: #ffffff">Late</span>
          </v-card>
          <v-card color="green" tile flat v-else>
            <span class="itemKey" style="color: #ffffff">Ok</span>
          </v-card>
        </v-flex>
        <v-flex xs12 sm9 align-self-center pl-2>
          <router-link :to="'/Hive/' + storage._id">
            <v-layout wrap justify-start>
              <v-flex xs6 text-left>
                <span class="itemKey">Colony Id</span><br />
                <span class="itemValue">
                  {{ storage.colonyidentifier }}
                </span>
              </v-flex>
              <v-flex xs6 text-left>
                <span class="itemKey">Colony Status</span><br />
                <span class="itemValue">
                  {{ storage.colonystatus }}
                </span>
              </v-flex>
              <v-flex xs6 text-left>
                <span class="itemKey">Source</span><br />
                <span class="itemValue">
                  {{ storage.source }}
                </span>
              </v-flex>
              <v-flex xs6 text-left>
                <span class="itemKey">Queen</span><br />
                <span class="itemValue">
                  <span v-if="storage.hasqueen">Yes</span>
                  <span v-else>No</span>
                </span>
              </v-flex>
              <v-flex xs12 text-left>
                <span class="itemKey">Added on</span>
                <span class="itemValue" style="font-size: 14px">
                  {{ formatDate(storage.createddate) }}
                </span>
              </v-flex>
            </v-layout>
          </router-link>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import VueQrcode from "vue-qrcode";
export default {
  props: ["storage"],
  components: {
    VueQrcode,
  },
  data() {
    return {};
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>