<template>
  <div>
    <v-card outlined>
      <v-layout wrap justify-center pa-2>
        <v-flex xs12 sm3 xl3 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs6 sm12>
              <v-img
                src="./../../assets/icons/weather.png"
                height="50px"
                contain
              ></v-img>
            </v-flex>
            <v-flex xs6 sm12 text-center>
              <span
                class="itemValue"
                style="font-size: 14px"
                v-html="formatDate(storage.createddate)"
              ></span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm9 xl9 align-self-center pl-2>
          <v-layout wrap justify-center>
            <v-flex xs12 text-left>
              <span class="itemKey">Status</span>
              <span v-if="storage.rain" class="itemValue pl-2">Rainy</span>
              <span v-if="storage.sunny" class="itemValue pl-2">Sunny</span>
              <span v-if="storage.overcast" class="itemValue pl-2">
                Overcast
              </span>
            </v-flex>
            <v-flex xs6 text-left>
              <span class="itemKey">Wind</span>
              <span class="itemValue pl-2">{{ storage.wind }}</span>
            </v-flex>
            <v-flex xs6 text-left>
              <span class="itemKey">Speed</span>
              <span class="itemValue pl-2"> {{ storage.windspeed }}Km/h </span>
            </v-flex>
            <v-flex xs6 text-left>
              <span class="itemKey">Wind Direction</span>
              <span class="itemValue pl-2">
                {{ storage.winddirection }}
              </span>
            </v-flex>
            <v-flex xs6 text-left>
              <span class="itemKey">Temperature</span>
              <span class="itemValue pl-2">
                {{ storage.temperature }}&deg;C
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " <br/>" +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>