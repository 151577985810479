<template>
  <div>
    <v-dialog
      v-model="imageViewerDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageViewer v-bind:storage="storage.image" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-card outlined>
      <v-layout wrap justify-center pa-2>
        <v-flex xs3 align-self-center style="cursor: pointer">
          <v-img
            v-if="storage.image"
            :src="mediaURL + storage.image"
            height="100px"
            contain
            @click="imageViewerDialog = true"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="#FDCE48"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <span class="itemKey" v-else>No Image</span>
        </v-flex>
        <v-flex xs9 align-self-center pl-2>
          <v-layout wrap justify-start>
            <v-flex xs12 text-left>
              <span class="itemKey">Tree</span>
              <span class="itemValue pl-2">
                <span v-if="storage.tree">Yes</span>
                <span v-else>No</span>
              </span>
            </v-flex>
            <v-flex xs12 text-left>
              <span class="itemKey">Crop</span>
              <span class="itemValue pl-2">
                <span v-if="storage.crop">Yes</span>
                <span v-else>No</span>
              </span>
            </v-flex>
            <v-flex xs12 text-left>
              <span class="itemKey">Shrub</span>
              <span class="itemValue pl-2">
                <span v-if="storage.shrub">Yes</span>
                <span v-else>No</span>
              </span>
            </v-flex>
            <v-flex xs12 text-left>
              <!-- <span class="itemKey">Date</span> -->
              <span class="itemValue" style="font-size: 14px">
                {{ formatDate(storage.createddate) }}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      imageViewerDialog: false,
    };
  },
  methods: {
    winStepper(windowData) {
      this.imageViewerDialog = windowData.imageViewer;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>